import React from "react";
import HeaderWithLogo from "../components/HeaderWithLogo";
import Footer from "../components/Footer";
import styled from "styled-components";
import Navbar from "../components/Navbar";

import step1 from "../assets/images/screenshots/step-1-navigate-to-private-apps.png";
import step3 from "../assets/images/screenshots/step-3-provide-name-and-select-file.png";
import step6 from "../assets/images/screenshots/step-6-install-app.png";
import step7 from "../assets/images/screenshots/step-7-view-app-in-private-apps.png";
import step8 from "../assets/images/screenshots/step-8-open-ticket.png";

const Step = styled.div`
  margin-top: 50px;
  margin-bottom: 100px;
  font-size: 18px;
`;

const Header = styled.div`
  margin-bottom: 15px;
  font-size: 26px;
  padding-right: 20px;
  font-weight: bold;
`;

const Description = styled.div`
  margin-bottom: 30px;
`;

const Image = styled.img`
  width: 100%;
`;

const Centered = styled.div`
  text-align: center;
`;

const GetStarted = () => {
  return (
    <>
      <Navbar hideLink={true} />

      <section className="bg-primary-alt has-divider">
        <div className="container" style={{ paddingTop: "60px" }}>
          <HeaderWithLogo title="Globalize Translator Installation Instrutions" />
          <Centered>
            Install the Globalize for Zendesk in 3 easy steps:
          </Centered>
          <div className="row justify-content-center">
            <div className="col-xl-9 col-lg-10">
              <Step>
                <Header>Step 1</Header>
                <Description>
                  Login to your Zendesk account, navigate to the private apps
                  section from the settings link and click on the{" "}
                  <strong>Upload private app</strong> button.{" "}
                </Description>
                <Centered>
                  <Image src={step1} alt="Step 1" />
                </Centered>
              </Step>

              <Step>
                <Header>Step 2</Header>
                <Description>
                  Download the Globalize Translator App from the link below.{" "}
                </Description>
                <Centered style={{ marginBottom: "30px" }}>
                  <a
                    href="/widgets/ticket-translator-v2.0.0.zip"
                    className="btn btn-large btn-primary"
                  >
                    Click here to download Globalize Titcket Translator app.
                  </a>
                </Centered>
                <Description>
                  Upload the file to Zendesk by selecting it in the{" "}
                  <strong>App File</strong> section.{" "}
                </Description>
                <Centered>
                  <Image src={step3} alt="Step 3" />
                </Centered>
                <Description style={{ marginTop: "30px" }}>
                  Click on the <strong>Upload</strong> button and accept the
                  agreement.{" "}
                </Description>
              </Step>

              <Step>
                <Header>Step 3</Header>
                <Description>
                  Configure the restrctions and click on the{" "}
                  <strong>Install</strong> button to install the app.{" "}
                </Description>
                <Centered>
                  <Image src={step6} alt="Step 6" />
                </Centered>
              </Step>

              <hr />

              <p
                className="lead opacity-70"
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  marginTop: "80px"
                }}
              >
                The installation is now complete!
              </p>

              <Step>
                <Description>
                  You should see the Globalize Ticket Translator app under your
                  Zendesk private applications.
                </Description>
                <Centered>
                  <Image
                    src={step7}
                    alt="Step 7"
                    style={{ marginBottom: "15px" }}
                  />
                </Centered>
                <Description>
                  When you open a Zendesk ticket, the Globalize Ticket
                  Translator will appear to the right of the ticket. It will
                  automatically detect the ticket language and translate it, if
                  necessary.
                </Description>
                <Centered>
                  <Image src={step8} alt="Step 8" style={{ width: "400px" }} />
                </Centered>
              </Step>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default GetStarted;
