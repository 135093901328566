import React from "react";
import { Link } from "react-router-dom";

const Navbar = ({hideLink}) => {
  return (
    <div className="navbar-container">
        <nav
          className="navbar navbar-expand-lg justify-content-between navbar-light border-bottom-0 bg-white"
          data-sticky="top"
        >
          <div className="container">
            <div className="col flex-fill px-0 d-flex justify-content-between">
              <a className="navbar-brand mr-0 fade-page" href="/">
                Globalize
                <span className="ml-2 text-muted h6">for Zendesk</span>
              </a>
            </div>
            <div className="collapse navbar-collapse justify-content-end col flex-fill px-0">
              { !hideLink ? (
                <Link
                to="/get-started"
                className="btn btn-outline-primary ml-lg-3"
              >
                Get Started
              </Link>
              ) : ""}                
            </div>
          </div>
        </nav>
      </div>
  );
};

export default Navbar;
