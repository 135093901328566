import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Home from './pages/Home';
import GetStarted from './pages/GetStarted';
import TermsOfService from './pages/Terms';
import PrivacyPolicy from './pages/Privacy';

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/get-started" component={GetStarted} />
        <Route path="/terms" component={TermsOfService} />
        <Route path="/privacy" component={PrivacyPolicy} />
        <Redirect to="/" />
      </Switch>
    </div>
  );
}

export default App;
