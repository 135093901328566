import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import HeaderWithLogo from "../components/HeaderWithLogo";

const TermsOfService = () => {
  return (
    <>
      <Navbar />

      <section className="bg-primary-alt has-divider">
        <div className="container" style={{ paddingTop: "60px" }}>
          <HeaderWithLogo title="Terms of Service" />

          <div
            notranslate=""
            class="col-md-10 col-lg-8 offset-lg-2 offset-md-1"
          >
            <p>
              Welcome to Globalize for Zendesk (“Globalize”). Globalize offers a
              system for translating Zendesk tickets (the “Service”). This
              service is provided by Translation Exchange Inc. Our website is
              located at{" "}
              <a href="http://www.translationexchange.com">
                www.translationexchange.com
              </a>{" "}
              (the “Site”). Please read these Terms of Service (the “Terms”)
              carefully because they govern your use of our Service accessible
              via third party applications that include the Service (“Apps”).
            </p>
            <br />
            <h3>Agreement to Terms</h3>
            <p>
              By using our Service, you agree to be bound by these Terms. If you
              don’t agree to these Terms, do not use the Service.
            </p>
            <h3>Changes to Terms or Service</h3>
            <p>
              We may modify the Terms at any time. If we do, we’ll let you know
              by posting the modified Terms on the Site or through other
              communications. You should review the modified Terms because if
              you continue to use the Service after we’ve told you that the
              Terms have been modified, you are indicating to us that you agree
              to be bound by the modified Terms. If you don’t agree to be bound
              by the modified Terms you can’t use the Service anymore. We may
              change or discontinue all or any part of the Service, at any time
              and without notice.
            </p>
            <br />
            <h3>Who May Use the Service</h3>
            <h4>Eligibility</h4>
            <p>
              You may use the Service only if you are 13 years or older and are
              not barred from using the Service under applicable law.
            </p>
            <h4>Registration and Your Information</h4>
            <p>
              If you want to use the Service you’ll have to create an account
              (“Account”). You can do this using one of the Apps that provide
              you with access to the Service or through your account with
              certain thirdparty social networking services such as Facebook or
              Twitter (each, an “SNS Account”). If you create an Account through
              one of the Apps you will provide your email address and create a
              password. If you choose the SNS Account option we’ll create your
              Account by extracting from your SNS Account certain personal
              information such as your name and email address and other personal
              information that your privacy settings on the SNS Account permit
              us to access.
            </p>
            <p>
              It’s important that you provide us with accurate, complete and
              up-to-date information for your Account and you agree to update
              such information to keep it accurate, complete and up-to-date. If
              you don’t, we might have to suspend or terminate your Account. You
              agree that you won’t disclose your Account password to anyone and
              you’ll notify us immediately of any unauthorized use of your
              Account. You’re responsible for all activities that occur under
              your Account, whether or not you know about them.
            </p>
            <h3>Feedback</h3>
            <p>
              We welcome feedback, comments and suggestions for improvements to
              the Service (“Feedback”). You can submit Feedback by emailing us
              at .. If you provide us any Feedback, you agree that we may use,
              copy, modify, create derivative works of and otherwise exploit the
              Feedback for any purpose.
            </p>
            <h3>Content and Content Rights</h3>
            <p>
              For purposes of these Terms “Content” means text, graphics,
              images, music, software, audio, video, works of authorship of any
              kind, and information or other materials that are posted,
              generated, provided or otherwise made available through the
              Service.
            </p>
            <h3>Content Ownership</h3>
            <p>
              Translation Exchange and its licensors exclusively own all right,
              title and interest in and to the Site, Service and Content,
              including all associated intellectual property rights. You
              acknowledge that the Site, Service and Content are protected by
              copyright, trademark, and other laws of the United States and
              foreign countries. You agree not to remove, alter or obscure any
              copyright, trademark, service mark or other proprietary rights
              notices incorporated in or accompanying the Site, Service or
              Content.
            </p>
            <h4>Rights in Content Granted by Translation Exchange</h4>
            <p>
              Subject to your compliance with these Terms, Translation Exchange
              grants you a limited, non-exclusive, nontransferable license to
              access and view the Content solely in connection with your
              permitted use of the Site and Service and solely for your personal
              and non-commercial purposes.
            </p>
            <h3>General Prohibitions</h3>
            <p>You agree not to do any of the following:</p>
            <ul>
              <li>
                <p>
                  Use, display, mirror or frame the Service, or any individual
                  element within the Service, Translation Exchange’s name, any
                  Translation Exchange trademark, logo or other proprietary
                  information, or the layout and design of any page or form
                  contained on a page, without Translation Exchange’s express
                  written consent;
                </p>
              </li>
              <li>
                <p>
                  Access, tamper with, or use non-public areas of the Service,
                  Translation Exchange’s computer systems, or the technical
                  delivery systems of Translation Exchange’s providers;
                </p>
              </li>
              <li>
                <p>
                  Attempt to probe, scan, or test the vulnerability of any
                  Translation Exchange system or network or breach any security
                  or authentication measures;
                </p>
              </li>
              <li>
                <p>
                  Avoid, bypass, remove, deactivate, impair, descramble or
                  otherwise circumvent any technological measure implemented by
                  Translation Exchange or any of Translation Exchange’s
                  providers or any other third party (including another user) to
                  protect the Service or Content;
                </p>
              </li>
              <li>
                <p>
                  Attempt to access or search the Service or Content or download
                  Content from the Service through the use of any engine,
                  software, tool, agent, device or mechanism (including spiders,
                  robots, crawlers, data mining tools or the like) other than
                  the software and/or search agents provided by Translation
                  Exchange or other generally available third party web
                  browsers;
                </p>
              </li>
              <li>
                <p>
                  Send any unsolicited or unauthorized advertising, promotional
                  materials, email, junk mail, spam, chain letters or other form
                  of solicitation;
                </p>
              </li>
              <li>
                <p>
                  Use any meta tags or other hidden text or metadata utilizing a
                  Translation Exchange trademark, logo, URL or product name
                  without Translation Exchange’s express written consent;
                </p>
              </li>
              <li>
                <p>
                  Use the Service or Content for any commercial purpose or for
                  the benefit of any third party or in any manner not permitted
                  by these Terms;
                </p>
              </li>
              <li>
                <p>
                  Forge any TCP/IP packet header or any part of the header
                  information in any email or newsgroup posting, or in any way
                  use the Service or Content to send altered, deceptive or false
                  source-identifying information;
                </p>
              </li>
              <li>
                <p>
                  Attempt to decipher, decompile, disassemble or reverse
                  engineer any of the software used to provide the Service or
                  Content;
                </p>
              </li>
              <li>
                <p>
                  Interfere with, or attempt to interfere with, the access of
                  any user, host or network, including, without limitation,
                  sending a virus, overloading, flooding, spamming, or
                  mail-bombing the Service;
                </p>
              </li>
              <li>
                <p>
                  Collect or store any personally identifiable information from
                  the Site or Service from other users of the Service without
                  their express permission;
                </p>
              </li>
              <li>
                <p>
                  Impersonate or misrepresent your affiliation with any person
                  or entity;
                </p>
              </li>
              <li>
                <p>Violate any applicable law or regulation; or</p>
              </li>
              <li>
                <p>
                  Encourage or enable any other individual to do any of the
                  foregoing.
                </p>
              </li>
            </ul>
            <p>
              Although we’re not obligated to monitor access to or use of the
              Service or Content or to review or edit any Content, we have the
              right to do so for the purpose of operating the Service, to ensure
              compliance with these Terms, or to comply with applicable law or
              other legal requirements. We reserve the right, but are not
              obligated, to remove or disable access to any Content, at any time
              and without notice, including, but not limited to, if we, at our
              sole discretion, consider any Content to be objectionable or in
              violation of these Terms. We have the right to investigate
              violations of these Terms or conduct that affects the Service. We
              may also consult and cooperate with law enforcement authorities to
              prosecute users who violate the law.
            </p>
            <h3>Links to Third Party Websites or Resources</h3>
            <p>
              The Service and Site may contain links to third-party websites or
              resources. We provide these links only as a convenience and are
              not responsible for the content, products or services on or
              available from those websites or resources or links displayed on
              such sites. You acknowledge sole responsibility for, and assume
              all risk arising from, your use of any third-party websites or
              resources.
            </p>
            <h3>Termination</h3>
            <p>
              We may terminate your access to and use of the Service, at our
              sole discretion, at any time and without notice to you. You may
              cancel your Account at any time by sending an email to us at{" "}
              <a href="mailto:support@translationexchange.com">.</a>. Upon any
              termination, discontinuation or cancellation of Services or your
              Account, the following provisions will survive: Content and
              Content Rights, Content Ownership (except for licenses granted to
              you), Termination, Warranty Disclaimers, Indemnity, Limitation of
              Liability, Dispute Resolution, and General Terms.
            </p>
            <h3>Warranty Disclaimers</h3>
            <p>
              THE SERVICE AND CONTENT ARE PROVIDED “AS IS,” WITHOUT WARRANTY OF
              ANY KIND. WITHOUT LIMITING THE FOREGOING, WE EXPLICITLY DISCLAIM
              ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
              PARTICULAR PURPOSE, QUIET ENJOYMENT AND NON-INFRINGEMENT AND ANY
              WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. We
              make no warranty that the Service will meet your requirements or
              be available on an uninterrupted, secure, or error-free basis. We
              make no warranty regarding the quality, accuracy, timeliness,
              truthfulness, completeness or reliability of any Content.
            </p>
            <h3>Indemnity</h3>
            <p>
              You will indemnify, defend, and hold harmless Translation Exchange
              and its officers, directors, employees and agents, from and
              against any claims, disputes, demands, liabilities, damages,
              losses, and costs and expenses, including, without limitation,
              reasonable legal and accounting fees, arising out of or in any way
              connected with (i) your access to or use of the Service or Content
              or (ii) your violation of these Terms.
            </p>
            <h3>Limitation of Liability</h3>
            <p>
              NEITHER Translation Exchange NOR ANY OTHER PARTY INVOLVED IN
              CREATING, PRODUCING, OR DELIVERING THE SERVICE OR CONTENT WILL BE
              LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL
              DAMAGES, INCLUDING LOST PROFITS, LOSS OF DATA OR GOODWILL, SERVICE
              INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE OR THE COST OF
              SUBSTITUTE SERVICE ARISING OUT OF OR IN CONNECTION WITH THESE
              TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICE OR
              CONTENT, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING
              NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND
              WHETHER OR NOT Translation Exchange HAS BEEN INFORMED OF THE
              POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH
              HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. SOME
              JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
              LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE
              LIMITATION MAY NOT APPLY TO YOU.
            </p>
            <p>
              IN NO EVENT WILL Translation Exchange’S TOTAL LIABILITY ARISING
              OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR
              INABILITY TO USE THE SERVICE OR CONTENT EXCEED FIFTY DOLLARS . THE
              LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF
              THE BASIS OF THE BARGAIN BETWEEN Translation Exchange AND YOU.
            </p>
            <h3>Dispute Resolution</h3>
            <h4>Governing Law</h4>
            <p>
              These Terms and any action related thereto will be governed by the
              laws of the State of California without regard to its conflict of
              laws provisions.
            </p>
            <h4>Agreement to Arbitrate</h4>
            <p>
              You and Translation Exchange agree that any dispute, claim or
              controversy arising out of or relating to these Terms or the
              breach, termination, enforcement, interpretation or validity
              thereof or the use of the Service or Content (collectively,
              “Disputes”) will be settled by binding arbitration, except that
              each party retains the right: (i) to bring an individual action in
              small claims court and (ii) to seek injunctive or other equitable
              relief in a court of competent jurisdiction to prevent the actual
              or threatened infringement, misappropriation or violation of a
              party’s copyrights, trademarks, trade secrets, patents or other
              intellectual property rights (the action described in the
              foregoing clause (ii), an “IP Protection Action”). The exclusive
              jurisdiction and venue of any IP Protection Action will be the
              state and federal courts located in the Northern District of
              California and each of the parties hereto waives any objection to
              jurisdiction and venue in such courts. You acknowledge and agree
              that you and Translation Exchange are each waiving the right to a
              trial by jury or to participate as a plaintiff or class member in
              any purported class action or representative proceeding. Further,
              unless both you and Translation Exchange otherwise agree in
              writing, the arbitrator may not consolidate more than one person's
              claims, and may not otherwise preside over any form of any class
              or representative proceeding. If this specific paragraph is held
              unenforceable, then the entirety of this “Dispute Resolution”
              section will be deemed void. Except as provided in the preceding
              sentence, this “Dispute Resolution” section will survive any
              termination of these Terms.
            </p>
            <h4>Arbitration Rules</h4>
            <p>
              The arbitration will be administered by the American Arbitration
              Association (“AAA”) in accordance with the Commercial Arbitration
              Rules and the Supplementary Procedures for Consumer Related
              Disputes (the “AAA Rules”) then in effect, except as modified by
              this “Dispute Resolution” section. (The AAA Rules are available at{" "}
              <a href="http://www.adr.org/arb_med">www.adr.org/arb_med</a> or by
              calling the AAA at 1.) The Federal Arbitration Act will govern the
              interpretation and enforcement of this Section.
            </p>
            <h4>Arbitration Process</h4>
            <p>
              A party who desires to initiate arbitration must provide the other
              party with a written Demand for Arbitration as specified in the
              AAA Rules. (The AAA provides a form Demand for Arbitration at{" "}
              <a href="http://www.adr.org/aaa/ShowPDF?doc=ADRSTG_004175">
                http://www.adr.org/aaa/ShowPDF?doc=ADRSTG_004175
              </a>{" "}
              and a separate form for California residents at{" "}
              <a href="http://www.adr.org/aaa/ShowPDF?doc=ADRSTG_015822">
                http://www.adr.org/aaa/ShowPDF?doc=ADRSTG_015822
              </a>
              .) The arbitrator will be either a retired judge or an attorney
              licensed to practice law and will be selected by the parties from
              the AAA’s roster of arbitrators. If the parties are unable to
              agree upon an arbitrator within seven (7) days of delivery of the
              Demand for Arbitration, then the AAA will appoint the arbitrator
              in accordance with the AAA Rules.
            </p>
            <h4>Arbitration Location and Procedure</h4>
            <p>
              Unless you and Translation Exchange otherwise agree, the
              arbitration will be conducted in the county where you reside. If
              your claim does not exceed 0 then the arbitration will be
              conducted solely on the basis of the documents that you and
              Translation Exchange submit to the arbitrator, unless you request
              a hearing or the arbitrator determines that a hearing is
              necessary. If your claim exceeds 0 your right to a hearing will be
              determined by the AAA Rules. Subject to the AAA Rules, the
              arbitrator will have the discretion to direct a reasonable
              exchange of information by the parties, consistent with the
              expedited nature of the arbitration.
            </p>
            <h4>Arbitrator’s Decision</h4>
            <p>
              The arbitrator will render an award within the time frame
              specified in the AAA Rules. The arbitrator’s decision will include
              the essential findings and conclusions upon which the arbitrator
              based the award. Judgment on the arbitration award may be entered
              in any court having jurisdiction thereof. The arbitrator’s award
              of damages must be consistent with the terms of the “Limitation of
              Liability” section above as to the types and amounts of damages
              for which a party may be held liable. The arbitrator may award
              declaratory or injunctive relief only in favor of the claimant and
              only to the extent necessary to provide relief warranted by the
              claimant’s individual claim. If you prevail in arbitration you
              will be entitled to an award of attorneys’ fees and expenses, to
              the extent provided under applicable law. Translation Exchange
              will not seek, and hereby waives all rights it may have under
              applicable law to recover, attorneys’ fees and expenses if it
              prevails in arbitration.
            </p>
            <h4>Fees</h4>
            <p>
              Your responsibility to pay any AAA filing, administrative and
              arbitrator fees will be solely as set forth in the AAA Rules.
              However, if your claim for damages does not exceed 0 Translation
              Exchange will pay all such fees unless the arbitrator finds that
              either the substance of your claim or the relief sought in your
              Demand for Arbitration was frivolous or was brought for an
              improper purpose (as measured by the standards set forth in
              Federal Rule of Civil Procedure 11(b)).
            </p>
            <h4>Changes</h4>
            <p>
              Notwithstanding the provisions of the “Changes to Terms of
              Service” section above, if Translation Exchange changes this
              “Dispute Resolution” section after the date you first accepted
              these Terms (or accepted any subsequent changes to these Terms),
              you may reject any such change by sending us written notice
              (including by email to{" "}
              <a href="mailto:support@translationexchange.com">.</a>) within 30
              days of the date such change became effective, as indicated in the
              “Last Updated” date above or in the date of Translation Exchange’s
              email to you notifying you of such change. By rejecting any
              change, you are agreeing that you will arbitrate any Dispute
              between you and Translation Exchange in accordance with the
              provisions of this “Dispute Resolution” section as of the date you
              first accepted these Terms (or accepted any subsequent changes to
              these Terms).
            </p>
            <h3>General Terms</h3>
            <p>
              These Terms constitute the entire and exclusive understanding and
              agreement between Translation Exchange and you regarding the
              Service and Content, and these Terms supersede and replace any and
              all prior oral or written understandings or agreements between
              Translation Exchange and you regarding the Site, Service and
              Content. If for any reason a court of competent jurisdiction finds
              any provision of these Terms invalid or unenforceable, that
              provision will be enforced to the maximum extent permissible and
              the other provisions of these Terms will remain in full force and
              effect.
            </p>
            <p>
              You may not assign or transfer these Terms, by operation of law or
              otherwise, without Translation Exchange’s prior written consent.
              Any attempt by you to assign or transfer these Terms, without such
              consent, will be null and of no effect. Translation Exchange may
              freely assign or transfer these Terms without restriction. Subject
              to the foregoing, these Terms will bind and inure to the benefit
              of the parties, their successors and permitted assigns.
            </p>
            <p>
              Any notices or other communications provided by Translation
              Exchange under these Terms, including those regarding
              modifications to these Terms, will be given by Translation
              Exchange (i) via email; or (ii) by posting to the Service. For
              notices made by e-mail, the date of receipt will be deemed the
              date on which such notice is transmitted.
            </p>
            <p>
              Translation Exchange’s failure to enforce any right or provision
              of these Terms will not be considered a waiver of those rights.
              The waiver of any such right or provision will be effective only
              if in writing and signed by a duly authorized representative of
              Translation Exchange. Except as expressly set forth in these
              Terms, the exercise by either party of any of its remedies under
              these Terms will be without prejudice to its other remedies under
              these Terms or otherwise.
            </p>
            <h3>Contact Information</h3>
            <p>
              If you have any questions about these Terms or the Service please
              contact Translation Exchange at{" "}
              <a href="mailto:info@translationexchange.com">
                info@translationexchange.com
              </a>
              .
            </p>
          </div>
          <div
            notranslate=""
            class="col-md-10 col-lg-8 offset-lg-2 offset-md-1"
          >
            <p>
              Welcome to Globalize for Zendesk (“Globalize”). Globalize for
              offers a system for translting Zendesk tickets (the “Service”).
              Our website is located at{" "}
              <a href="https://globalize.io">globalize.io</a> (the “Site”).
              Please read these Terms of Service (the “Terms”) carefully because
              they govern your use of our Service accessible via third party
              applications that include the Service (“Apps”).
            </p>
            <br />
            <h3>Agreement to Terms</h3>
            <p>
              By using our Service, you agree to be bound by these Terms. If you
              don’t agree to these Terms, do not use the Service.
            </p>
            <h3>Changes to Terms or Service</h3>
            <p>
              We may modify the Terms at any time. If we do, we’ll let you know
              by posting the modified Terms on the Site or through other
              communications. You should review the modified Terms because if
              you continue to use the Service after we’ve told you that the
              Terms have been modified, you are indicating to us that you agree
              to be bound by the modified Terms. If you don’t agree to be bound
              by the modified Terms you can’t use the Service anymore. We may
              change or discontinue all or any part of the Service, at any time
              and without notice.
            </p>
            <br />
            <h3>Who May Use the Service</h3>
            <h4>Eligibility</h4>
            <p>
              You may use the Service only if you are 13 years or older and are
              not barred from using the Service under applicable law.
            </p>
            <h4>Registration and Your Information</h4>
            <p>
              If you want to use the Service you’ll have to create an account
              (“Account”). You can do this using one of the Apps that provide
              you with access to the Service or through your account with
              certain thirdparty social networking services such as Facebook or
              Twitter (each, an “SNS Account”). If you create an Account through
              one of the Apps you will provide your email address and create a
              password. If you choose the SNS Account option we’ll create your
              Account by extracting from your SNS Account certain personal
              information such as your name and email address and other personal
              information that your privacy settings on the SNS Account permit
              us to access.
            </p>
            <p>
              It’s important that you provide us with accurate, complete and
              up-to-date information for your Account and you agree to update
              such information to keep it accurate, complete and up-to-date. If
              you don’t, we might have to suspend or terminate your Account. You
              agree that you won’t disclose your Account password to anyone and
              you’ll notify us immediately of any unauthorized use of your
              Account. You’re responsible for all activities that occur under
              your Account, whether or not you know about them.
            </p>
            <h3>Feedback</h3>
            <p>
              We welcome feedback, comments and suggestions for improvements to
              the Service (“Feedback”). You can submit Feedback by emailing us
              at .. If you provide us any Feedback, you agree that we may use,
              copy, modify, create derivative works of and otherwise exploit the
              Feedback for any purpose.
            </p>
            <h3>Content and Content Rights</h3>
            <p>
              For purposes of these Terms “Content” means text, graphics,
              images, music, software, audio, video, works of authorship of any
              kind, and information or other materials that are posted,
              generated, provided or otherwise made available through the
              Service.
            </p>
            <h3>Content Ownership</h3>
            <p>
              Globalize and its licensors exclusively own all right, title and
              interest in and to the Site, Service and Content, including all
              associated intellectual property rights. You acknowledge that the
              Site, Service and Content are protected by copyright, trademark,
              and other laws of the United States and foreign countries. You
              agree not to remove, alter or obscure any copyright, trademark,
              service mark or other proprietary rights notices incorporated in
              or accompanying the Site, Service or Content.
            </p>
            <h4>Rights in Content Granted by Globalize</h4>
            <p>
              Subject to your compliance with these Terms, Globalize grants you
              a limited, non-exclusive, nontransferable license to access and
              view the Content solely in connection with your permitted use of
              the Site and Service and solely for your personal and
              non-commercial purposes.
            </p>
            <h3>General Prohibitions</h3>
            <p>You agree not to do any of the following:</p>
            <ul>
              <li>
                <p>
                  Use, display, mirror or frame the Service, or any individual
                  element within the Service, Globalize’s name, any Globalize
                  trademark, logo or other proprietary information, or the
                  layout and design of any page or form contained on a page,
                  without Globalize’s express written consent;
                </p>
              </li>
              <li>
                <p>
                  Access, tamper with, or use non-public areas of the Service,
                  Globalize’s computer systems, or the technical delivery
                  systems of Globalize’s providers;
                </p>
              </li>
              <li>
                <p>
                  Attempt to probe, scan, or test the vulnerability of any
                  Globalize system or network or breach any security or
                  authentication measures;
                </p>
              </li>
              <li>
                <p>
                  Avoid, bypass, remove, deactivate, impair, descramble or
                  otherwise circumvent any technological measure implemented by
                  Globalize or any of Globalize’s providers or any other third
                  party (including another user) to protect the Service or
                  Content;
                </p>
              </li>
              <li>
                <p>
                  Attempt to access or search the Service or Content or download
                  Content from the Service through the use of any engine,
                  software, tool, agent, device or mechanism (including spiders,
                  robots, crawlers, data mining tools or the like) other than
                  the software and/or search agents provided by Globalize or
                  other generally available third party web browsers;
                </p>
              </li>
              <li>
                <p>
                  Send any unsolicited or unauthorized advertising, promotional
                  materials, email, junk mail, spam, chain letters or other form
                  of solicitation;
                </p>
              </li>
              <li>
                <p>
                  Use any meta tags or other hidden text or metadata utilizing a
                  Globalize trademark, logo, URL or product name without
                  Globalize’s express written consent;
                </p>
              </li>
              <li>
                <p>
                  Use the Service or Content for any commercial purpose or for
                  the benefit of any third party or in any manner not permitted
                  by these Terms;
                </p>
              </li>
              <li>
                <p>
                  Forge any TCP/IP packet header or any part of the header
                  information in any email or newsgroup posting, or in any way
                  use the Service or Content to send altered, deceptive or false
                  source-identifying information;
                </p>
              </li>
              <li>
                <p>
                  Attempt to decipher, decompile, disassemble or reverse
                  engineer any of the software used to provide the Service or
                  Content;
                </p>
              </li>
              <li>
                <p>
                  Interfere with, or attempt to interfere with, the access of
                  any user, host or network, including, without limitation,
                  sending a virus, overloading, flooding, spamming, or
                  mail-bombing the Service;
                </p>
              </li>
              <li>
                <p>
                  Collect or store any personally identifiable information from
                  the Site or Service from other users of the Service without
                  their express permission;
                </p>
              </li>
              <li>
                <p>
                  Impersonate or misrepresent your affiliation with any person
                  or entity;
                </p>
              </li>
              <li>
                <p>Violate any applicable law or regulation; or</p>
              </li>
              <li>
                <p>
                  Encourage or enable any other individual to do any of the
                  foregoing.
                </p>
              </li>
            </ul>
            <p>
              Although we’re not obligated to monitor access to or use of the
              Service or Content or to review or edit any Content, we have the
              right to do so for the purpose of operating the Service, to ensure
              compliance with these Terms, or to comply with applicable law or
              other legal requirements. We reserve the right, but are not
              obligated, to remove or disable access to any Content, at any time
              and without notice, including, but not limited to, if we, at our
              sole discretion, consider any Content to be objectionable or in
              violation of these Terms. We have the right to investigate
              violations of these Terms or conduct that affects the Service. We
              may also consult and cooperate with law enforcement authorities to
              prosecute users who violate the law.
            </p>
            <h3>Links to Third Party Websites or Resources</h3>
            <p>
              The Service and Site may contain links to third-party websites or
              resources. We provide these links only as a convenience and are
              not responsible for the content, products or services on or
              available from those websites or resources or links displayed on
              such sites. You acknowledge sole responsibility for, and assume
              all risk arising from, your use of any third-party websites or
              resources.
            </p>
            <h3>Termination</h3>
            <p>
              We may terminate your access to and use of the Service, at our
              sole discretion, at any time and without notice to you. You may
              cancel your Account at any time by sending an email to us at{" "}
              <a href="mailto:support@globalize.io">.</a>. Upon any termination,
              discontinuation or cancellation of Services or your Account, the
              following provisions will survive: Content and Content Rights,
              Content Ownership (except for licenses granted to you),
              Termination, Warranty Disclaimers, Indemnity, Limitation of
              Liability, Dispute Resolution, and General Terms.
            </p>
            <h3>Warranty Disclaimers</h3>
            <p>
              THE SERVICE AND CONTENT ARE PROVIDED “AS IS,” WITHOUT WARRANTY OF
              ANY KIND. WITHOUT LIMITING THE FOREGOING, WE EXPLICITLY DISCLAIM
              ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
              PARTICULAR PURPOSE, QUIET ENJOYMENT AND NON-INFRINGEMENT AND ANY
              WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. We
              make no warranty that the Service will meet your requirements or
              be available on an uninterrupted, secure, or error-free basis. We
              make no warranty regarding the quality, accuracy, timeliness,
              truthfulness, completeness or reliability of any Content.
            </p>
            <h3>Indemnity</h3>
            <p>
              You will indemnify, defend, and hold harmless Globalize and its
              officers, directors, employees and agents, from and against any
              claims, disputes, demands, liabilities, damages, losses, and costs
              and expenses, including, without limitation, reasonable legal and
              accounting fees, arising out of or in any way connected with (i)
              your access to or use of the Service or Content or (ii) your
              violation of these Terms.
            </p>
            <h3>Limitation of Liability</h3>
            <p>
              NEITHER Globalize NOR ANY OTHER PARTY INVOLVED IN CREATING,
              PRODUCING, OR DELIVERING THE SERVICE OR CONTENT WILL BE LIABLE FOR
              ANY INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES,
              INCLUDING LOST PROFITS, LOSS OF DATA OR GOODWILL, SERVICE
              INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE OR THE COST OF
              SUBSTITUTE SERVICE ARISING OUT OF OR IN CONNECTION WITH THESE
              TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICE OR
              CONTENT, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING
              NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND
              WHETHER OR NOT Globalize HAS BEEN INFORMED OF THE POSSIBILITY OF
              SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO
              HAVE FAILED OF ITS ESSENTIAL PURPOSE. SOME JURISDICTIONS DO NOT
              ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL
              OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO
              YOU.
            </p>
            <p>
              IN NO EVENT WILL Globalize’S TOTAL LIABILITY ARISING OUT OF OR IN
              CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE
              THE SERVICE OR CONTENT EXCEED FIFTY DOLLARS . THE LIMITATIONS OF
              DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF
              THE BARGAIN BETWEEN Globalize AND YOU.
            </p>
            <h3>Dispute Resolution</h3>
            <h4>Governing Law</h4>
            <p>
              These Terms and any action related thereto will be governed by the
              laws of the State of California without regard to its conflict of
              laws provisions.
            </p>
            <h4>Agreement to Arbitrate</h4>
            <p>
              You and Globalize agree that any dispute, claim or controversy
              arising out of or relating to these Terms or the breach,
              termination, enforcement, interpretation or validity thereof or
              the use of the Service or Content (collectively, “Disputes”) will
              be settled by binding arbitration, except that each party retains
              the right: (i) to bring an individual action in small claims court
              and (ii) to seek injunctive or other equitable relief in a court
              of competent jurisdiction to prevent the actual or threatened
              infringement, misappropriation or violation of a party’s
              copyrights, trademarks, trade secrets, patents or other
              intellectual property rights (the action described in the
              foregoing clause (ii), an “IP Protection Action”). The exclusive
              jurisdiction and venue of any IP Protection Action will be the
              state and federal courts located in the Northern District of
              California and each of the parties hereto waives any objection to
              jurisdiction and venue in such courts. You acknowledge and agree
              that you and Globalize are each waiving the right to a trial by
              jury or to participate as a plaintiff or class member in any
              purported class action or representative proceeding. Further,
              unless both you and Globalize otherwise agree in writing, the
              arbitrator may not consolidate more than one person's claims, and
              may not otherwise preside over any form of any class or
              representative proceeding. If this specific paragraph is held
              unenforceable, then the entirety of this “Dispute Resolution”
              section will be deemed void. Except as provided in the preceding
              sentence, this “Dispute Resolution” section will survive any
              termination of these Terms.
            </p>
            <h4>Arbitration Rules</h4>
            <p>
              The arbitration will be administered by the American Arbitration
              Association (“AAA”) in accordance with the Commercial Arbitration
              Rules and the Supplementary Procedures for Consumer Related
              Disputes (the “AAA Rules”) then in effect, except as modified by
              this “Dispute Resolution” section. (The AAA Rules are available at{" "}
              <a href="http://www.adr.org/arb_med">www.adr.org/arb_med</a> or by
              calling the AAA at 1.) The Federal Arbitration Act will govern the
              interpretation and enforcement of this Section.
            </p>
            <h4>Arbitration Process</h4>
            <p>
              A party who desires to initiate arbitration must provide the other
              party with a written Demand for Arbitration as specified in the
              AAA Rules. (The AAA provides a form Demand for Arbitration at{" "}
              <a href="http://www.adr.org/aaa/ShowPDF?doc=ADRSTG_004175">
                http://www.adr.org/aaa/ShowPDF?doc=ADRSTG_004175
              </a>{" "}
              and a separate form for California residents at{" "}
              <a href="http://www.adr.org/aaa/ShowPDF?doc=ADRSTG_015822">
                http://www.adr.org/aaa/ShowPDF?doc=ADRSTG_015822
              </a>
              .) The arbitrator will be either a retired judge or an attorney
              licensed to practice law and will be selected by the parties from
              the AAA’s roster of arbitrators. If the parties are unable to
              agree upon an arbitrator within seven (7) days of delivery of the
              Demand for Arbitration, then the AAA will appoint the arbitrator
              in accordance with the AAA Rules.
            </p>
            <h4>Arbitration Location and Procedure</h4>
            <p>
              Unless you and Globalize otherwise agree, the arbitration will be
              conducted in the county where you reside. If your claim does not
              exceed 0 then the arbitration will be conducted solely on the
              basis of the documents that you and Globalize submit to the
              arbitrator, unless you request a hearing or the arbitrator
              determines that a hearing is necessary. If your claim exceeds 0
              your right to a hearing will be determined by the AAA Rules.
              Subject to the AAA Rules, the arbitrator will have the discretion
              to direct a reasonable exchange of information by the parties,
              consistent with the expedited nature of the arbitration.
            </p>
            <h4>Arbitrator’s Decision</h4>
            <p>
              The arbitrator will render an award within the time frame
              specified in the AAA Rules. The arbitrator’s decision will include
              the essential findings and conclusions upon which the arbitrator
              based the award. Judgment on the arbitration award may be entered
              in any court having jurisdiction thereof. The arbitrator’s award
              of damages must be consistent with the terms of the “Limitation of
              Liability” section above as to the types and amounts of damages
              for which a party may be held liable. The arbitrator may award
              declaratory or injunctive relief only in favor of the claimant and
              only to the extent necessary to provide relief warranted by the
              claimant’s individual claim. If you prevail in arbitration you
              will be entitled to an award of attorneys’ fees and expenses, to
              the extent provided under applicable law. Globalize will not seek,
              and hereby waives all rights it may have under applicable law to
              recover, attorneys’ fees and expenses if it prevails in
              arbitration.
            </p>
            <h4>Fees</h4>
            <p>
              Your responsibility to pay any AAA filing, administrative and
              arbitrator fees will be solely as set forth in the AAA Rules.
              However, if your claim for damages does not exceed 0 Globalize
              will pay all such fees unless the arbitrator finds that either the
              substance of your claim or the relief sought in your Demand for
              Arbitration was frivolous or was brought for an improper purpose
              (as measured by the standards set forth in Federal Rule of Civil
              Procedure 11(b)).
            </p>
            <h4>Changes</h4>
            <p>
              Notwithstanding the provisions of the “Changes to Terms of
              Service” section above, if Globalize changes this “Dispute
              Resolution” section after the date you first accepted these Terms
              (or accepted any subsequent changes to these Terms), you may
              reject any such change by sending us written notice (including by
              email to <a href="mailto:support@globalize.io">.</a>) within 30
              days of the date such change became effective, as indicated in the
              “Last Updated” date above or in the date of Globalize's email to
              you notifying you of such change. By rejecting any change, you are
              agreeing that you will arbitrate any Dispute between you and
              Globalize in accordance with the provisions of this “Dispute
              Resolution” section as of the date you first accepted these Terms
              (or accepted any subsequent changes to these Terms).
            </p>
            <h3>General Terms</h3>
            <p>
              These Terms constitute the entire and exclusive understanding and
              agreement between Globalize and you regarding the Service and
              Content, and these Terms supersede and replace any and all prior
              oral or written understandings or agreements between Globalize and
              you regarding the Site, Service and Content. If for any reason a
              court of competent jurisdiction finds any provision of these Terms
              invalid or unenforceable, that provision will be enforced to the
              maximum extent permissible and the other provisions of these Terms
              will remain in full force and effect.
            </p>
            <p>
              You may not assign or transfer these Terms, by operation of law or
              otherwise, without Globalize’s prior written consent. Any attempt
              by you to assign or transfer these Terms, without such consent,
              will be null and of no effect. Globalize may freely assign or
              transfer these Terms without restriction. Subject to the
              foregoing, these Terms will bind and inure to the benefit of the
              parties, their successors and permitted assigns.
            </p>
            <p>
              Any notices or other communications provided by Globalize under
              these Terms, including those regarding modifications to these
              Terms, will be given by Globalize (i) via email; or (ii) by
              posting to the Service. For notices made by e-mail, the date of
              receipt will be deemed the date on which such notice is
              transmitted.
            </p>
            <p>
              Globalize’s failure to enforce any right or provision of these
              Terms will not be considered a waiver of those rights. The waiver
              of any such right or provision will be effective only if in
              writing and signed by a duly authorized representative of
              Globalize. Except as expressly set forth in these Terms, the
              exercise by either party of any of its remedies under these Terms
              will be without prejudice to its other remedies under these Terms
              or otherwise.
            </p>
            <h3>Contact Information</h3>
            <p>
              If you have any questions about these Terms or the Service please
              contact Globalize at{" "}
              <a href="mailto:support@globalize.io">support@globalize.io</a>.
            </p>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default TermsOfService;
