import React from "react";
import { Link } from "react-router-dom";
import Footer  from "../components/Footer";
import Navbar  from "../components/Navbar";

import illustration1 from "../assets/images/illustration-1.png";
import illustration2 from "../assets/images/illustration-2.png";

import logo1 from "../assets/images//adcolony-logo-grey.png";
import logo3 from "../assets/images//gopro-logo-grey.png";
import logo4 from "../assets/images//castlight-logo-grey.png";

const Home = () => {
  return (
    <>
      <Navbar />

      <section>
        <div className="container">
          <div className="row justify-content-center text-center mb-6">
            <div className="col-xl-8 col-lg-9 col-md-10">
              <h1 className="display-4">
                Support your customers <br />
                in any language
              </h1>
              <p className="lead opacity-70">
                Globalize for Zendesk offers a fully integrated translation
                solution for your customer success team.
              </p>
              <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center align-items-sm-start mt-5">
                <div className="d-flex flex-column mx-1 text-center">
                  <Link
                    to="/get-started"
                    className="btn btn-lg btn-primary mb-2"
                  >
                    <span>Get Started</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-9 col-lg-10">
              <img src={illustration1} alt="illustration" />
            </div>
          </div>
        </div>
      </section>
      <section className="pt-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-11">
              <div className="row">
                <div className="col-md-6 d-flex mb-4">
                  <div className="icon-round bg-primary mr-3">
                    <i className="fal fa-users fa-2x text-primary" />
                  </div>
                  <div>
                    <h4 className="mb-2">Understand your customers</h4>
                    <p className="lead pr-xl-4 opacity-60">
                      Incoming messages are automatically translated into your
                      agent’s preferred language.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 d-flex mb-4">
                  <div className="icon-round bg-primary mr-3">
                    <i className="fal fa-comments fa-2x text-primary" />
                  </div>
                  <div>
                    <h4 className="mb-2">Effective communication</h4>
                    <p className="lead pr-xl-4 opacity-60">
                      Your agent's custom response messages will be immediately
                      translated into the customer's native language.
                    </p>
                  </div>
                </div>

                <div className="col-md-6 d-flex mb-4">
                  <div className="icon-round bg-primary mr-3">
                    <i className="fal fa-smile fa-2x text-primary" />
                  </div>
                  <div>
                    <h4 className="mb-2">Happier customers</h4>
                    <p className="lead pr-xl-4 opacity-60">
                      Put your customers at ease by communicating with them in
                      their preffered language.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 d-flex mb-4">
                  <div className="icon-round bg-primary mr-3">
                    <i className="fal fa-clock fa-2x text-primary" />
                  </div>
                  <div>
                    <h4 className="mb-2">Faster resolution times</h4>
                    <p className="lead pr-xl-4 opacity-60">
                      Reduce the total time spent on handling tickets. Your
                      customers will appreciate it.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-primary-alt has-divider">
        <div className="container pb-0">
          <div className="row justify-content-center text-center mb-6">
            <div className="col-xl-8 col-lg-9">
              <h2 className="display-4 mx-xl-6">
                Communicate better and resolve issues faster
              </h2>
              <p className="lead opacity-70">
                Your customers need support in their native languages. Give your
                company's customer success team the tools to support your
                customers in any language.
              </p>
            </div>
          </div>

          <div className="row justify-content-center mt-6 mb-lg-n3">
            <div className="col-xl-8 col-lg-9 layer-1">
              <div className="mb-3">
                <img src={illustration2} alt="illustration" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-primary-alt mb-lg-n7">
        <div className="container pb-md-6">
          <div className="row">
            <div className="col-md-4 d-flex mb-4">
              <i className="fal fa-tachometer fa-2x" />
              <div className="ml-3">
                <h5>Instant Translation</h5>
                <p className="opacity-70">
                  Instant machine translation of both incoming customer messages
                  and outgoing responses.
                </p>
              </div>
            </div>
            <div className="col-md-4 d-flex mb-4">
              <i className="fal fa-cog fa-2x" />
              <div className="ml-3">
                <h5>Fully Integrated</h5>
                <p className="opacity-70">
                  Native Zendesk integration eliminates the hassle of copying
                  and pasting of text. Globalize for Zendesk is fully automatic.
                </p>
              </div>
            </div>
            <div className="col-md-4 d-flex mb-4">
              <i className="fal fa-language fa-2x" />
              <div className="ml-3">
                <h5>Language Support</h5>
                <p className="opacity-70">
                  With more than 100+ languages supported, your agents will be
                  able to communicate with any customer.
                </p>
              </div>
            </div>
            <div className="col-md-4 d-flex mb-4">
              <i className="fal fa-bolt fa-2x" />
              <div className="ml-3">
                <h5>Language Detection</h5>
                <p className="opacity-70">
                  No need to guess an unfamiliar language. Globalize for Zendesk
                  detects customer's language automatically.
                </p>
              </div>
            </div>
            <div className="col-md-4 d-flex mb-4">
              <i className="fal fa-plug fa-2x" />
              <div className="ml-3">
                <h5>Easy Installation</h5>
                <p className="opacity-70">
                  Install the Globalize for Zendesk plugin in just a few
                  minutes.
                </p>
              </div>
            </div>
            <div className="col-md-4 d-flex mb-4">
              <i className="fal fa-life-ring fa-2x" />
              <div className="ml-3">
                <h5>Full Support</h5>
                <p className="opacity-70">Having issues, we're here to help.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-md-0">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="card card-body border-0 o-hidden mb-0 text-light bg-primary">
                <div className="position-relative d-flex flex-column flex-md-row justify-content-between align-items-center">
                  <div>
                    <div className="h4 mb-md-0">Ready to get started?</div>
                    <p className="lead opacity-60">
                      Communicate with customers in their language and increase
                      customers satisfaction.
                    </p>
                  </div>
                  <Link
                    to="/get-started"
                    className="btn btn-lg btn-white"
                  >
                    Get Started
                  </Link>
                </div>
                <div className="decoration layer-0">
                  <img
                    className="bg-primary-2"
                    src="assets/img/decorations/deco-blob-1.svg"
                    alt="deco-blob-1 decoration"
                    data-inject-svg
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-0">
        <div className="container">
          <div className="row text-center mb-5">
            <div className="col">
              <small className="text-uppercase">
                Trusted by companies like
              </small>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <ul className="d-flex flex-wrap justify-content-center list-unstyled">
                <li className="mx-3 mb-md-5">
                  <img
                    className="icon icon-md opacity-60"
                    src={logo1}
                    alt="icon"
                  />
                </li>
                <li className="mx-3 mb-md-5">
                  <img
                    className="icon icon-md opacity-60"
                    src={logo3}
                    alt="icon"
                  />
                </li>
                <li className="mx-3 mb-md-5">
                  <img
                    className="icon icon-md opacity-60"
                    src={logo4}
                    alt="icon"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      
      <Footer />
    </>
  );
};

export default Home;
