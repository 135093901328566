import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="pb-5 bg-primary-3 text-light">
      <div className="container">
        <div className="row mb-4 justify-content-center">
          <div className="col-auto">
            <a href="/" className="h3">
              Globalize
              <span className="ml-2 text-muted h6">for Zendesk</span>
            </a>
          </div>
        </div>

        <div className="row justify-content-center text-center">
          <div className="col-xl-10">
            <p className="text-muted ">
              &copy;2020 All Rights Reserved. Translation Exchange, Inc
            </p>
          </div>
        </div>
        <div className="row justify-content-center text-center">
          <div className="col-xl-10">
            <p className="text-muted mt-2" style={{fontSize: "13px"}}>
              <Link to="/terms" className="text-muted mr-2">Terms of Service</Link>
              <Link to="/privacy" className="text-muted ml-2">Privacy Policy</Link>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
