import React from "react";
import styled from "styled-components";

import logo from "../assets/images/logo.png";
const Image = styled.img``;

const HeaderWithLogo = ({title}) => {
  return (
    <div className="row justify-content-center text-center mb-2">
      <div className="col-xl-8 col-lg-9 col-md-10">
        <Image
          src={logo}
          alt="Logo"
          style={{ width: "100px", marginBottom: "40px" }}
        />
        <p className="lead opacity-70" style={{ fontWeight: "bold" }}>
          {title}
        </p>
      </div>
    </div>
  );
};

export default HeaderWithLogo;
